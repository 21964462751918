// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import { useAuthContext } from 'src/auth/hooks'; // Importa useAuthContext
import { fcustomCurrency } from 'src/utils/format-number'; // Importa fcustomCurrency
import { Searchbar, AccountPopover, SettingsButton } from '../_common'; // Assicurati che il percorso sia corretto
import { HEADER, NAV } from '../config-layout';
// ----------------------------------------------------------------------

type Props = {
    onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
    const theme = useTheme();
    const settings = useSettingsContext();
    const { user } = useAuthContext(); // Ottieni i dettagli dell'utente

    const isNavHorizontal = settings.themeLayout === 'horizontal';
    const isNavMini = settings.themeLayout === 'mini';
    const lgUp = useResponsive('up', 'lg');
    const offset = useOffSetTop(HEADER.H_DESKTOP); // Ripristina HEADER se necessario
    const offsetTop = offset && !isNavHorizontal;

    const renderContent = (
        <>
            {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}
            {!lgUp && (
                <IconButton onClick={onOpenNav}>
                    <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
                </IconButton>
            )}
            <Searchbar />
            <Stack
                flexGrow={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 0.5, sm: 1 }}
            >
                <Stack direction="row" alignItems="center" spacing={1}>
                    <img src="/assets/icons/wallet.png" alt="Wallet Icon" style={{ width: 24, height: 24 }} />
                    <Typography variant="caption" component="div">
                        {fcustomCurrency(user?.balance)}
                    </Typography>
                </Stack>
                <SettingsButton />
                <AccountPopover />
            </Stack>
        </>
    );

    return (
        <AppBar
            sx={{
                height: HEADER.H_MOBILE, // Assicurati che HEADER sia definito
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default
                }),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter
                }),
                ...(lgUp && {
                    width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`, // Assicurati che NAV sia definito
                    height: HEADER.H_DESKTOP, // Assicurati che HEADER sia definito
                    ...(offsetTop && {
                        height: HEADER.H_DESKTOP_OFFSET // Assicurati che HEADER sia definito
                    }),
                    ...(isNavHorizontal && {
                        width: 1,
                        bgcolor: 'background.default',
                        height: HEADER.H_DESKTOP_OFFSET, // Assicurati che HEADER sia definito
                        borderBottom: `dashed 1px ${theme.palette.divider}`
                    }),
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_MINI + 1}px)` // Assicurati che NAV sia definito
                    })
                })
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 }
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}
